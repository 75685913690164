import {
  dateWithUtcOffset, diffDays, momentSetHoursAndMinutes,
} from './formatDate';

import { REQUEST_STATUS_LABELS } from '../constants/hotelBookRequest';
import { VAT_RATES_AS_NUMBERS } from 'bi/constants/hotel';

const extractIntercomChatId = (url) => {
  if (!url) return null;

  const regex = /conversation\/(\d+)/;
  const regUrl = url.match(regex);

  if (!regUrl) {
    return null;
  }

  return regUrl[1];
};

const prepareDataForBookingRequest = (cart, accountId, userEmail) => {
  const {
    IntercomLink,
    Items,
    UserAnalytics,
  } = cart;

  const {
    CartId,
    TripId,
    EmployeeCartItems,
    Employees,
    Data,
    UserAnalytics: itemUserAnalytics,
  } = Items[0];

  const {
    CompanyId,
    ProjectId,
    DepartmentId,
  } = EmployeeCartItems[0];

  const hotelInfo = JSON.parse(Data);

  const {
    hotel: {
      Name,
      Email,
      Phone,
      ClassificatorId,
    },
    room: { Name: roomName },
    Rate: {
      Amenities,
      Price,
      CheckinDate,
      CheckoutDate,
      CustomCheckInDate,
      CustomCheckOutDate,
    },
  } = hotelInfo;

  const {
    Base,
    Commission,
    HasVAT,
    EarlyCheckIn,
    LateCheckOut,
  } = Price;

  const {
    CancellationInfo,
    CancellationPenalties,
    HasMeal,
    Meal: {
      Include,
      Price: MealPrice,
      Name: MealName,
      Category: MealCategory,
    },
  } = Amenities;

  const employeesList = Employees.map(({ Employee }) => Employee.Id);

  const daysDiff = diffDays(momentSetHoursAndMinutes(CheckinDate, 0, 0), momentSetHoursAndMinutes(CheckoutDate, 0, 0));

  const isFreeCancellation = !!CancellationInfo;
  const cancellationDateWithoutFee = isFreeCancellation ? dateWithUtcOffset(CancellationInfo) : null;
  const cancellationDateWithFee = isFreeCancellation ? dateWithUtcOffset(CancellationPenalties[0].From) : null;
  const cancellationPenaltyAdditional = isFreeCancellation ? CancellationPenalties[0].Additional : false;

  const checkingDate = dateWithUtcOffset(CheckinDate);
  const checkoutDate = dateWithUtcOffset(CheckoutDate);

  const cancellationFeeTotal = CancellationPenalties.length ? String(CancellationPenalties[0].Total) : '0';
  const cancellationFeeBase = CancellationPenalties.length ? Number(CancellationPenalties[0].Base) : 0;

  const intercomChatId = Number(extractIntercomChatId(IntercomLink));

  const preparedVatRate = HasVAT ? VAT_RATES_AS_NUMBERS.TWENTY : 0; // Если с НДС, то это Савади и VatRate = 20

  const customCheckinPrice = CustomCheckInDate ? Number(EarlyCheckIn) : 0;
  const customChecoutPrice = CustomCheckOutDate ? Number(LateCheckOut) : 0;

  const baseWithoutECLCPrices = Base - customCheckinPrice - customChecoutPrice;
  const dailyPrice = CancellationPenalties ? parseFloat((baseWithoutECLCPrices / daysDiff).toFixed(2)) : null;

  return {
    companyId: CompanyId,
    hotelInfo: {
      hotelId: Number(ClassificatorId),
      hotelName: Name || '',
      worksWithVat: HasVAT,
      priceInclVat: false,
      vatRate: preparedVatRate,
      isSelfEmployed: false,
      emails: [Email] || [],
      phone: Phone || '',
    },
    hotelRates: [
      {
        employees: employeesList,
        roomCategory: roomName,
        price: {
          dailyPrice,
          commission: Number(Commission) || 0,
          earlyCheckin: customCheckinPrice,
          lateCheckout: customChecoutPrice,
        },
        amenities: {
          hasMeal: HasMeal,
          meal: {
            include: Include,
            name: MealName,
            category: Number(MealCategory),
            price: Number(MealPrice) || 0,
          },
          hasFreeCancellation: isFreeCancellation,
          freeCancellationDate: cancellationDateWithoutFee,
          cancellationPenalties: [
            {
              from: cancellationDateWithFee,
              base: cancellationFeeBase,
              total: cancellationFeeTotal,
              additional: cancellationPenaltyAdditional,
            },
          ],
        },
        checkinTime: checkingDate,
        checkoutTime: checkoutDate,
        earlyCheckin: CustomCheckOutDate || false,
        lateCheckout: CustomCheckOutDate || false,
        departmentId: DepartmentId || 0,
        projectId: ProjectId || 0,
        userAnalytics: itemUserAnalytics || [],
      },
    ],
    sendAt: null,
    conversationId: intercomChatId,
    customerEmail: userEmail,
    accountId: Number(accountId),
    tripId: TripId || 0,
    cartId: CartId,
    letterSubject: '',
    additionalComment: '',
    status: REQUEST_STATUS_LABELS.Incomplete,
    userAnalytics: UserAnalytics || [],
  };
};

export { prepareDataForBookingRequest, extractIntercomChatId };
